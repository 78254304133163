<template>
    <div class="inner-section application-form-wrapper">
        <bread-cumb />
        <!-- <div class="form-wrapper application-form-wrapper"> -->
            <body-card>
                <template v-slot:headerTitle>
                    <h4 class="card-title">{{ $t('lrcpnServicePanel.acid_import_clearance_application') }}</h4>
                </template>
                <template v-slot:body>
                    <b-overlay :show="loading">
                    <form-wizard shape="circle" title="" subtitle="" :start-index.sync="activeIndex">
                        <tab-content :title="$t('lrcpnServicePanel.applicant_info')" icon="ri-file-cloud-line" :before-change="tabSubmitApplicantInfo">
                            <ApplicantForm :key="formData" :tabFormData="formData" ref="applicant_info_form"/>
                        </tab-content>
                        <tab-content :title="$t('lrcpnServicePanel.business_info')" icon="ri-list-settings-line" :before-change="tabSubmitBusinessInfo">
                            <BusinessInfoForm :key="formData.business_info" :tabFormData="formData.business_info" ref="business_info_form" :draft="draft"/>
                        </tab-content>
                        <template slot="footer" slot-scope="props">
                            <div class="wizard-footer-right">
                                <wizard-button class="btn btn-secondary" @click.native="props.prevTab(), step--"  v-if="props.activeTabIndex > 0" :style="props.fillButtonStyle">{{ $t('globalTrans.back') }}</wizard-button>
                                <wizard-button
                                    type="submit"
                                    class="btn btn-primary ml-1 mr-1"
                                    title="Create Draft and Save!"
                                    @click.native="props.nextTab(), draft = 1"
                                >
                                <i class="ri-draft-line"></i> {{$t('globalTrans.save_draft')}}
                                </wizard-button>
                                <wizard-button
                                    type="submit"
                                    v-if="!props.isLastStep"
                                    @click.native="props.nextTab(), isNext = true"
                                    class="btn btn-success ml-1 mr-1"
                                    title="Draft and go to next!"
                                    :style="props.fillButtonStyle"
                                ><i class="ri-save-2-line"></i> {{$t('globalTrans.draft_n_next')}}
                                </wizard-button>
                                <wizard-button
                                    v-else
                                    type="submit"
                                    class="btn btn-success ml-1"
                                    title="Submit!"
                                    @click.native="finalSave(), app_status = 2"
                                >
                                <i class="ri-send-plane-fill"></i> {{ $t('globalTrans.finalSave')}}
                                </wizard-button>
                                <wizard-button
                                    type="button"
                                    class="btn btn-danger ml-1 mr-1"
                                    title="Cancel"
                                >
                                    <router-link :to="{ name: 'lrcpn_service_panel.acid_import_clearance_application' }" class="text-white"><i class="ri-close-circle-line"></i> {{ $t('globalTrans.cancel') }}</router-link>
                                </wizard-button>
                            </div>
                        </template>
                    </form-wizard>
                    </b-overlay>
                </template>
            </body-card>
        <!-- </div> -->
    </div>
  </template>
  <script>
    import BreadCumb from '@/components/BreadCumb.vue'
    import { FormWizard, TabContent } from 'vue-form-wizard'
    import 'vue-form-wizard/dist/vue-form-wizard.min.css'
    import WizardButton from 'vue-form-wizard/src/components/WizardButton.vue'
    import ApplicantForm from './ApplicantForm.vue'
    import BusinessInfoForm from './BusinessInfoForm'
    import { acidImportClearanceApplicationShowApi } from '../../api/routes'
    import RestApi, { lrcpnServiceBaseUrl } from '@/config/api_config'
    export default {
        components: { BreadCumb, FormWizard, TabContent, WizardButton, ApplicantForm, BusinessInfoForm },
        data () {
            return {
                app_id: this.$route.params.id,
                activeIndex: 0,
                baseUrl: lrcpnServiceBaseUrl,
                draft: 0,
                formData: {
                    id: 0,
                    applicant_name: this.$store.state.Auth.authUser.name,
                    applicant_name_bn: this.$store.state.Auth.authUser.name_bn,
                    applicant_mobile: this.$store.state.Auth.authUser.mobile,
                    applicant_email: this.$store.state.Auth.authUser.email,
                    same_as_permanent: 0,
                    applicant_permanent_area_type_id: 0,
                    applicant_permanent_division_id: 0,
                    applicant_permanent_district_id: 0,
                    applicant_permanent_city_corporation_id: 0,
                    applicant_permanent_pauroshoba_id: 0,
                    applicant_permanent_upazila_id: 0,
                    applicant_permanent_union_id: 0,
                    applicant_present_area_type_id: 0,
                    applicant_present_division_id: 0,
                    applicant_present_district_id: 0,
                    applicant_present_city_corporation_id: 0,
                    applicant_present_pauroshoba_id: 0,
                    applicant_present_upazila_id: 0,
                    applicant_present_union_id: 0,
                    business_info: {
                        app_id: this.$route.params.id ? this.$route.params.id : 0,
                        trade_license_info: {},
                        drug_license_info: {},
                        tax_info: {},
                        warehouse_info: {},
                        present_import_info: {},
                        any_case_field_under_acis_control_act: {},
                        acid_import_worth_value_infos: [],
                        previous_license_info: {}
                    }
                }
            }
        },
        created () {
            if (this.$route.params.id) {
              this.getFormData(this.$route.params.id)
            }
        },
        computed: {
            loading: function () {
                return this.$store.state.commonObj.loading
            }
        },
        methods: {
            finalSave () {
                this.$swal({
                    title: this.$t('globalTrans.final_save_msg'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('globalTrans.yes'),
                    cancelButtonText: this.$t('globalTrans.no'),
                    focusConfirm: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.tabSubmitBusinessInfo()
                    } else {
                        this.app_status = 1
                    }
                })
            },
            async tabSubmitApplicantInfo () {
                const result = await this.$refs.applicant_info_form.submit()
                if (result.success) {
                    this.formData = Object.assign({}, this.formData, result.model)
                    if (!this.$route.params.id) {
                        this.formData.business_info = Object.assign(
                            {},
                            this.formData.business_info,
                            { app_id: result.data.id }
                        )
                    }

                    if (this.draft) {
                        this.$router.push({ name: 'lrcpn_service_panel.acid_import_clearance_application' })
                    } else {
                        return true
                    }
                }
            },
            async tabSubmitBusinessInfo () {
                const result = await this.$refs.business_info_form.submit()
                if (result.success) {
                    this.$router.push({ name: 'lrcpn_service_panel.acid_import_clearance_application' })
                }
            },
            async getFormData (id) {
                this.$store.dispatch('mutateCommonProperties', { loading: true })
                  const result = await RestApi.getData(lrcpnServiceBaseUrl, acidImportClearanceApplicationShowApi + '/' + id)
                  if (result.success) {
                      if (result.data.business_info === null) {
                          result.data.business_info = Object.assign({}, this.formData.business_info, { app_id: result.data.id })
                      }
                      this.formData = Object.assign({}, this.formData, result.data)
                  }
                  this.$store.dispatch('mutateCommonProperties', { loading: false })
            }
        }
    }
  </script>
